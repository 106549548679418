import AV from './AV.js'
let data = null;
let polaroidGallery = (function () {

    var dataSize = {};
    var dataLength = 0;
    var currentItem = null;
    var navbarHeight = 60;
    var resizeTimeout = null;
    var autoPlay = true;
    var autoPlayTime = 10;
    var timer = null;


    function initBg() {
        let body = document.getElementsByTagName('body')[0];
        let code = new Date().getTime();
        body.style = `background-image: url('https://picsum.photos/2560/1600?${code}')`
    }

    function AvGetData(authorId) {
        const query = new AV.Query('wallpaper');
        query.equalTo("authorId", authorId);
        return query.find()
    }


    function polaroidGallery(autoIsshow=true,reload=false,authorId=false) {
        document.getElementById("gallery").innerHTML = '';
        timer && clearInterval(timer);
        console.log(authorId)
        if(!authorId)return
        observe();
        if(reload && data.length !=0){
            setGallery(data,autoIsshow);
            init();
        }else{
            console.log('请求')
            AvGetData(authorId).then(res => {
                data = res;
                console.log(res)
                setGallery(res,autoIsshow);
                init();
            });
        }
        
    }

    // http://fiveauto-l5.top/app_niu/1689656109877.webp .replace("http://fiveauto-l5.top", "/api")

    function setGallery(arr,autoIsshow) {
        var out = "";
        var i;
        for (i = 0; i < arr.length; i++) {
            if (autoIsshow) {
                if (arr[i].attributes.isshow) {
                    out += '<figure id="' + i + '">' +
                        '<img src="' + arr[i].attributes.url + '" alt="' + arr[i].attributes.name + '"/>' +
                        '<figcaption>' + arr[i].attributes.name + '</figcaption>' +
                        '</figure>';
                }
            } else {
                out += '<figure id="' + i + '">' +
                    '<img src="' + arr[i].attributes.url + '" alt="' + arr[i].attributes.name + '"/>' +
                    '<figcaption>' + arr[i].attributes.name + '</figcaption>' +
                    '</figure>';
            }
        }
        document.getElementById("gallery").innerHTML = out;
    }

    function observe() {
        var observeDOM = (function () {
            var MutationObserver = window.MutationObserver || window.WebKitMutationObserver,
                eventListenerSupported = window.addEventListener;

            return function (obj, callback) {
                if (MutationObserver) {
                    var obs = new MutationObserver(function (mutations, observer) {
                        if (mutations[0].addedNodes.length || mutations[0].removedNodes.length)
                            callback(mutations);
                    });

                    obs.observe(obj, { childList: true, subtree: false });
                }
                else if (eventListenerSupported) {
                    obj.addEventListener('DOMNodeInserted', callback, false);
                }
            }
        })();

        observeDOM(document.getElementById('gallery'), function (mutations) {
            var gallery = [].slice.call(mutations[0].addedNodes);
            var zIndex = 1;
            gallery.forEach(function (item) {
                var img = item.getElementsByTagName('img')[0];
                var first = true;
                img.addEventListener('load', function () {
                    if (first) {
                        currentItem = item;
                        first = false;
                    }
                    dataSize[item.id] = { item: item, width: item.offsetWidth, height: item.offsetHeight };

                    dataLength++;

                    item.addEventListener('click', function () {
                        select(item);
                        shuffleAll();
                        if (autoPlay) {
                            autoPlayImages();
                        }
                    });

                    shuffle(item, zIndex++);
                })
            });
        });
    }

    function init() {
        navbarHeight = autoPlay ? 60 : document.getElementById("nav").offsetHeight;
        navigation();

        window.addEventListener('resize', function () {
            if (resizeTimeout) {
                clearTimeout(resizeTimeout);
            }
            resizeTimeout = setTimeout(function () {
                shuffleAll();
                if (currentItem) {
                    select(currentItem);
                }
            }, 100);
        });
    }

    function select(item) {
        var scale = 1.8;
        var rotRandomD = 0;

        var initWidth = dataSize[item.id].width;
        var initHeight = dataSize[item.id].height;

        var newWidth = (initWidth * scale);
        var newHeight = initHeight * (newWidth / initWidth);

        var x = (window.innerWidth - newWidth) / 2;
        var y = (window.innerHeight - navbarHeight - newHeight) / 2;

        item.style.transformOrigin = '0 0';
        item.style.WebkitTransform = 'translate(' + x + 'px,' + y + 'px) rotate(' + rotRandomD + 'deg) scale(' + scale + ',' + scale + ')';
        item.style.msTransform = 'translate(' + x + 'px,' + y + 'px) rotate(' + rotRandomD + 'deg) scale(' + scale + ',' + scale + ')';
        item.style.transform = 'translate(' + x + 'px,' + y + 'px) rotate(' + rotRandomD + 'deg) scale(' + scale + ',' + scale + ')';
        item.style.zIndex = 999;

        currentItem = item;
    }

    function shuffle(item, zIndex) {
        var randomX = Math.random();
        var randomY = Math.random();
        var maxR = 45;
        var minR = -45;
        var rotRandomD = Math.random() * (maxR - minR) + minR;
        var rotRandomR = rotRandomD * Math.PI / 180;

        var rotatedW = Math.abs(item.offsetWidth * Math.cos(rotRandomR)) + Math.abs(item.offsetHeight * Math.sin(rotRandomR));
        var rotatedH = Math.abs(item.offsetWidth * Math.sin(rotRandomR)) + Math.abs(item.offsetHeight * Math.cos(rotRandomR));

        var x = Math.floor((window.innerWidth - rotatedW) * randomX);
        var y = Math.floor((window.innerHeight - rotatedH) * randomY);

        item.style.transformOrigin = '0 0';
        item.style.WebkitTransform = 'translate(' + x + 'px,' + y + 'px) rotate(' + rotRandomD + 'deg) scale(1)';
        item.style.msTransform = 'translate(' + x + 'px,' + y + 'px) rotate(' + rotRandomD + 'deg) scale(1)';
        item.style.transform = 'translate(' + x + 'px,' + y + 'px) rotate(' + rotRandomD + 'deg) scale(1)';
        item.style.zIndex = zIndex;
    }

    function shuffleAll() {
        var zIndex = 0;
        for (var id in dataSize) {
            if (id != currentItem.id) {
                shuffle(dataSize[id].item, zIndex++);
            }
        }
    }

    function navigation() {
        if (autoPlay) {
            autoPlayImages();
        } else {
            var next = document.getElementById('next');
            var preview = document.getElementById('preview');
            next.addEventListener('click', function () {
                var currentIndex = Number(currentItem.id) + 1;
                if (currentIndex >= dataLength) {
                    currentIndex = 0
                }
                select(dataSize[currentIndex].item);
                shuffleAll();
            });
            preview.addEventListener('click', function () {
                var currentIndex = Number(currentItem.id) - 1;
                if (currentIndex < 0) {
                    currentIndex = dataLength - 1
                }
                select(dataSize[currentIndex].item);
                shuffleAll();
            })
        }

    }

    function autoPlayImages() {
        let num = autoPlayTime;
        timer && clearInterval(timer);
        timer = setInterval(() => {
            num--;
            if (num < 1) {
                var currentIndex = Number(currentItem.id) - 1;
                if (currentIndex < 0) {
                    currentIndex = dataLength - 1
                }
                select(dataSize[currentIndex].item);
                shuffleAll();
                num = autoPlayTime;
            }
        }, 1000);
    }

    return polaroidGallery;
})();


export default polaroidGallery