<template>
  <div class="main">
    <div id="gallery" class="fullscreen"></div>
    <div v-if="id">
      <div class="btn btn-copy" data-clipboard-text="https://app.fiveauto.cn">💻</div>
      <div class="btn btn-isshow" @click="showClick()">{{ isshow ? '📴' : "📳" }}</div>
      <div class="btn btn-refresh" @click="refreshClick()">🚀</div>
    </div>

  </div>
</template>
<script>
import polaroidGallery from '../utils/polaroid-gallery'
import ClipboardJS from 'clipboard';
import Toast from "vant/lib/toast";
import "vant/lib/toast/style";
export default {
  data() {
    return {
      isshow: true,
      id: null,
    }
  },
  created() {
    let id = this.$route.query.id;
    this.id = id;
  },
  mounted() {
    if (!this.id) {
      Toast('缺少Id值');
      return
    }

    this.init();
    let clipboard = new ClipboardJS('.btn-copy');
    clipboard.on('success', function (e) {
      Toast('复制后台网址成功，请在浏览器打开！');
      e.clearSelection();
    });
  },
  methods: {
    init() {
      this.$nextTick(() => {
        let _this=this;
        window.onload = function () {
          new polaroidGallery(this.isshow, false, _this.id);
        }
      })
    },
    showClick() {
      new polaroidGallery(!this.isshow, true, this.id);
      this.isshow = !this.isshow;
    },
    refreshClick() {
      new polaroidGallery(this.isshow, false, this.id);
    },
  }
}
</script>
<style lang="scss">
.main {
  .btn {
    position: fixed;
    z-index: 1000;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 0px 0px 15px rgba($color: #000000, $alpha: 0.5);
    border: 2px solid #38cd7f;
    right: 20px;
    transition: all 0.2s;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: 1;
    }

    &:active {
      transform: scale(1.2);
    }
  }

  .btn-isshow {
    bottom: 120px;
    background-color: #fff;
  }

  .btn-refresh {
    bottom: 60px;
    background-color: yellow;
  }

  .btn-copy {
    bottom: 180px;
    background-color: blue($color: #000000);
  }
}
</style>
